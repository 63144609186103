import React, { useEffect } from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import { SEO, Donate, Listen, TrailerItem } from "components";
import {
	Banner,
	Description,
	EpisodeList,
	LatestEpisodesList,
	HostsList,
	SponsorList,
	Newsletter,
	AllEpisodesList,
	ExtendedDescription,
	Gallery,
	RelatedContent,
} from "components/podcast";
import useMenu from "state/useMenu";
/* Podcast Template
============================================================================= */

const PodcastTemplate = ({ pageContext, location }) => {
	// Config
	const { podcast, episodes } = pageContext;
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render
	return (
		<>
			<Helmet title={podcast.name} lang="en">
				<meta name="image" content={podcast.image.image.asset.url} />
				<meta property="og:title" content={podcast.name} />
				<meta
					property="og:image"
					content={podcast.image.image.asset.url}
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:image"
					content={podcast.image.image.asset.url}
				/>
				<meta property="og:url" content={location.href} />
				<meta property="og:description" content={podcast.blurb} />
				<meta property="og:type" content="article" />
			</Helmet>
			<Banner podcast={podcast} location={location} />
			<div className="w-full horizontal-padding">
				<TrailerItem
					podcast={podcast}
					noTranscript={podcast._rawTranscript === null}
				/>
			</div>
			<LatestEpisodesList episodes={episodes} podcast={podcast} />
			<ExtendedDescription
				description={podcast._rawExtendedDescription}
			/>
			<Gallery galleryItems={podcast.gallery} />
			<HostsList hosts={podcast.hosts} />
			<RelatedContent
				podcasts={podcast.related_podcasts}
				articles={podcast.related_articles}
			/>
			<SponsorList
				sponsors={podcast.podcast_sponsors}
				podcast={podcast}
			/>
		</>
	);
};

/* Export
============================================================================= */

export default PodcastTemplate;
